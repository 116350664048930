import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import Header from '../../components/common/Header';
import {collection, addDoc, query, where, getDocs} from "firebase/firestore";
import {db} from '../../firebase';
import {getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage";
import {useAuth} from '../../context/AuthContext';
import MyTrips from '../../components/Profile/MyTrips';
import styled from 'styled-components';
import {FaChevronDown, FaChevronUp} from 'react-icons/fa';
import {v4 as uuidv4} from 'uuid';

const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};

function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const PageWrapper = styled.div`
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
`;

const Title = styled.h2`
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
`;

const TitleInput = styled.input`
    width: calc(100% - 10px);
    padding: 5px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    height: 40px;
`;

const Subtitle = styled.p`
    font-size: 1rem;
    color: #555;
    margin-bottom: 30px;
    text-align: center;
`;

const Section = styled.div`
    margin-bottom: 20px;
`;

const TextInput = styled.textarea`
    width: calc(100% - 20px);
    height: 150px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    resize: none;
`;

const ImageUploadSection = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const ImageUploadBox = styled.div`
    width: 100px;
    height: 100px;
    border: 2px dashed #ccc;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    margin-bottom: 2rem;

    &:hover {
        border-color: #0096ff;
    }

    &::before {
        content: '+';
        font-size: 48px;
        color: #ccc;
    }
`;

const HiddenFileInput = styled.input`
    display: none;
`;

const ImagePreview = styled.div`
    position: relative;
    display: inline-block;
    margin-right: 10px;

    img {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        object-fit: cover;
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        background: red;
        color: white;
        border: none;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
`;

const ImagePreviewContainer = styled.div`
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
    padding: 10px 0;
`;

const SubmitButton = styled.button`
    padding: 1rem 1.5rem;
    border: none;
    font-size: 0.8rem;
    cursor: pointer;
    background-color: #0096FF;
    color: white;
    width: 100%;
    max-width: 800px;
    position: fixed; /* 하단에 고정 */
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1rem;

    &:disabled {
        background-color: #ccc;
    }
`;

const ToggleButton = styled.h3`
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #333;
    margin-bottom: 10px;
`;

const AddReviewPage = () => {
    const [value, setValue] = useState(0);
    const [hover, setHover] = useState(-1);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [selectedTrip, setSelectedTrip] = useState(null);
    const [imageURLs, setImageURLs] = useState([]);
    const [isTripSectionVisible, setIsTripSectionVisible] = useState(true);
    const [reviewedTrips, setReviewedTrips] = useState([]);
    const {user} = useAuth();
    const navigate = useNavigate();
    const storage = getStorage();
    const [imagePaths, setImagePaths] = useState([]);

    useEffect(() => {
        const fetchReviewedTrips = async () => {
            if (!user) return;

            const reviewsQuery = query(
                collection(db, "reviews"),
                where("userID", "==", user.uid)
            );
            const querySnapshot = await getDocs(reviewsQuery);

            const reviewedTripIDs = querySnapshot.docs.map(doc => doc.data().tripID);
            setReviewedTrips(reviewedTripIDs);
        };

        fetchReviewedTrips();
    }, [user]);

    const handleRatingChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleContentChange = (e) => {
        setContent(e.target.value);
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];

        // 여행 정보가 설정되지 않았을 경우 업로드 중단
        if (!selectedTrip?.id) {
            alert('여행 정보가 설정되지 않았습니다. 여행을 먼저 선택하세요.');
            return;
        }

        const tripID = selectedTrip.id; // 여행 ID 가져오기

        if (file && imagePaths.length < 5) {
            try {
                // 파일명을 UUID를 사용하여 고유하게 변경
                const uniqueFileName = `${uuidv4()}_${file.name}`;

                // Firebase Storage 경로 설정 (예: review_images/tripID/고유파일명)
                const storageRef = ref(storage, `review_images/${tripID}/${uniqueFileName}`);

                // 이미지 파일을 Firebase Storage에 업로드
                await uploadBytes(storageRef, file);

                // Storage 경로를 imagePaths에 추가
                const filePath = storageRef.fullPath;
                setImagePaths([...imagePaths, filePath]);

                // 다운로드 URL을 가져와 미리보기 URL로 사용
                const downloadURL = await getDownloadURL(storageRef);
                setImageURLs([...imageURLs, downloadURL]);

            } catch (error) {
                console.error("이미지 업로드 중 오류 발생: ", error);
                alert("이미지 업로드 중 오류가 발생했습니다.");
            }
        } else if (imagePaths.length >= 5) {
            alert('최대 5장까지 업로드할 수 있습니다.');
        }
    };

    const handleImageClick = () => {
        document.getElementById('imageInput').click();
    };

    const handleImageRemove = async (index) => {
        const imagePath = imagePaths[index]; // 삭제할 이미지의 경로 가져오기

        if (!imagePath) return;

        try {
            // Firebase Storage에서 이미지 삭제
            const imageRef = ref(storage, imagePath);
            await deleteObject(imageRef); // 이미지 삭제

            // 상태 업데이트 - 삭제된 이미지의 경로 및 미리보기 URL 제거
            setImagePaths(imagePaths.filter((_, i) => i !== index));
            setImageURLs(imageURLs.filter((_, i) => i !== index));

        } catch (error) {
            console.error('Firebase Storage에서 이미지 삭제 중 오류 발생: ', error);
            alert('이미지 삭제 중 오류가 발생했습니다.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user) {
            alert('로그인이 필요합니다.');
            return;
        }

        if (isSubmitDisabled) {
            let errorMsg = '작성완료 버튼을 활성화하려면:\n';
            if (!title) errorMsg += '- 제목을 입력하세요.\n';
            if (content.replace(/<[^>]+>/g, '').trim().length <= 10) errorMsg += '- 후기를 10자 이상 입력하세요.\n';
            if (value === 0) errorMsg += '- 별점을 남기세요.';

            alert(errorMsg);  // 알림으로 안내
            return;
        }

        try {
            // Firestore에 리뷰 문서 추가
            await addDoc(collection(db, "reviews"), {
                userID: user.uid,
                title,
                content,
                rating: value,
                createdAt: new Date(),
                tripID: selectedTrip.id, // tripID 필드도 추가
                trip: selectedTrip ? {
                    selectedActivity: selectedTrip.selectedActivity,
                    selectedRegion: selectedTrip.selectedRegion,
                    selectedRegionId: selectedTrip.selectedRegionId,
                    startDate: selectedTrip.startDate,
                    endDate: selectedTrip.endDate,
                } : null,
                images: imageURLs, // 다운로드 URL 대신 경로만 저장
                imagePaths: imagePaths,
            });
            alert("리뷰가 성공적으로 추가되었습니다!");
            navigate('/review');
        } catch (error) {
            console.error("리뷰 추가 중 오류 발생: ", error);
        }
    };

    const isSubmitDisabled = !title || content.replace(/<[^>]+>/g, '').trim().length <= 8 || value === 0;

    return (
        <>
            <Header/>
            <PageWrapper>
                <Title>리뷰 작성하기</Title>
                <Subtitle>이야기를 나눠주시면 <br/> 더 재밌는 여행을 만들어 갈 수 있어요!</Subtitle>

                <Section>
                    <ToggleButton onClick={() => setIsTripSectionVisible(!isTripSectionVisible)}>
                        여행 선택하기 {isTripSectionVisible ? <FaChevronUp/> : <FaChevronDown/>}
                    </ToggleButton>
                    {isTripSectionVisible && (
                        <MyTrips onSelectTrip={setSelectedTrip} showModal={false} disabledTrips={reviewedTrips}/>
                    )}
                </Section>

                <Section>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <Rating
                            name="hover-feedback"
                            value={value}
                            precision={0.5}
                            getLabelText={getLabelText}
                            onChange={handleRatingChange}
                            onChangeActive={(event, newHover) => setHover(newHover)}
                            size="large"
                            sx={{fontSize: '3rem'}}
                            emptyIcon={<StarIcon style={{opacity: 0.55}} fontSize="inherit"/>}
                        />
                        {value !== null && (
                            <Box sx={{
                                mt: 1,
                                fontSize: '1rem',
                                textAlign: 'center'
                            }}>{labels[hover !== -1 ? hover : value]}</Box>
                        )}
                    </Box>
                </Section>

                <Section>
                    <h3>제목</h3>
                    <TitleInput
                        type="text"
                        value={title}
                        onChange={handleTitleChange}
                        placeholder="제목을 입력하세요..."
                    />
                </Section>

                <Section>
                    <h3>후기 작성하기</h3>
                    <TextInput value={content} onChange={handleContentChange} placeholder="후기를 작성하세요..."/>
                </Section>

                <Section>
                    <h3>이미지 업로드 (최대 5장)</h3>
                    <ImageUploadSection>
                        <ImagePreviewContainer>
                            {/* 이미지 미리보기 표시 */}
                            {imageURLs.map((url, index) => (
                                <ImagePreview key={index}>
                                    <img src={url} alt={`Uploaded ${index}`}/>
                                    <button onClick={() => handleImageRemove(index)}>x</button>
                                </ImagePreview>
                            ))}

                            {/* 최대 5장까지만 업로드 허용 */}
                            {imageURLs.length < 5 && (
                                <ImageUploadBox onClick={handleImageClick}>
                                    <HiddenFileInput
                                        id="imageInput"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                    />
                                </ImageUploadBox>
                            )}
                        </ImagePreviewContainer>
                    </ImageUploadSection>
                </Section>

                <SubmitButton onClick={handleSubmit}>
                    작성완료
                </SubmitButton>
            </PageWrapper>
        </>
    );
};

export default AddReviewPage;
