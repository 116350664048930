import React, { useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import {
  signInWithCredential,
  linkWithCredential,
  PhoneAuthProvider,
  EmailAuthProvider,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import Header from '../common/Header';
import EmailInput from './SignUp/EmailInput';
import BirthdayInput from './SignUp/BirthdayInput';
import PasswordInput from './SignUp/PasswordInput';
import NameInput from './SignUp/NameInput';
import PhoneInput from './SignUp/PhoneInput';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TermsModal from './TermsModal';
import './SignUpPage.css';

const initialState = {
  fullEmail: '',
  password: '',
  confirmPassword: '',
  name: '',
  birthday: '',
  emailCheckMessage: '',
  phoneCheckMessage: '',
  error: '',
  passwordError: '',
  termsChecked: false,
  modalIsOpen: false,
  loading: false,
};

const signUpReducer = (state, action) => {
  switch (action.type) {
    case 'SET_EMAIL':
      return { ...state, fullEmail: action.payload };
    case 'SET_PASSWORD':
      return { ...state, password: action.payload };
    case 'SET_CONFIRM_PASSWORD':
      return { ...state, confirmPassword: action.payload };
    case 'SET_NAME':
      return { ...state, name: action.payload };
    case 'SET_BIRTHDAY':
      return { ...state, birthday: action.payload };
    case 'SET_EMAIL_CHECK_MESSAGE':
      return { ...state, emailCheckMessage: action.payload };
    case 'SET_PHONE_CHECK_MESSAGE':
      return { ...state, phoneCheckMessage: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_PASSWORD_ERROR':
      return { ...state, passwordError: action.payload };
    case 'SET_TERMS_CHECKED':
      return { ...state, termsChecked: action.payload };
    case 'SET_MODAL_OPEN':
      return { ...state, modalIsOpen: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

const SignUpPage = () => {
  const [state, dispatch] = useReducer(signUpReducer, initialState);
  const [verificationId, setVerificationId] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  const handlePhoneChange = (formattedPhone) => {
    setPhoneNumber(formattedPhone);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    dispatch({ type: 'SET_ERROR', payload: '' });
    dispatch({ type: 'SET_LOADING', payload: true });

    // 비밀번호와 비밀번호 확인이 일치하는지 확인
    if (state.password !== state.confirmPassword) {
      dispatch({ type: 'SET_ERROR', payload: '비밀번호가 일치하지 않습니다.' });
      dispatch({ type: 'SET_LOADING', payload: false });
      return;
    }

    // 이메일 중복 체크 확인
    if (!state.emailCheckMessage.includes('사용 가능한')) {
      dispatch({ type: 'SET_ERROR', payload: '이메일 중복 체크를 확인해주세요.' });
      dispatch({ type: 'SET_LOADING', payload: false });
      return;
    }

    // 이용약관 동의 확인
    if (!state.termsChecked) {
      dispatch({ type: 'SET_ERROR', payload: '이용약관에 동의해주세요.' });
      dispatch({ type: 'SET_LOADING', payload: false });
      return;
    }

    try {
      // 인증 코드로 PhoneAuthCredential 생성
      const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);

      // 휴대전화 번호로 로그인하여 계정 생성
      const userCredential = await signInWithCredential(auth, phoneCredential);
      const user = userCredential.user;

      // 이메일과 비밀번호 자격 증명 생성
      const emailCredential = EmailAuthProvider.credential(state.fullEmail, state.password);

      // 이메일과 비밀번호를 기존 계정에 연결
      await linkWithCredential(user, emailCredential);

      // Firestore에 사용자 정보 저장
      const currentDate = new Date();
      await setDoc(doc(db, 'users', user.uid), {
        uid: user.uid,
        email: state.fullEmail,
        name: state.name,
        birthday: state.birthday,
        phone: phoneNumber,
        profilePicture:
          'https://firebasestorage.googleapis.com/v0/b/randomtour-8421b.appspot.com/o/profilePictures%2Fdefault-profile.png?alt=media&token=c7da2234-f459-47d2-9b88-8bd568c9b20f',
        createdAt: currentDate,
      });

      navigate('/signup-complete');
    } catch (error) {
      if (error.code === 'auth/invalid-verification-code') {
        dispatch({ type: 'SET_ERROR', payload: '잘못된 인증 코드입니다. 다시 시도해주세요.' });
      } else if (error.code === 'auth/email-already-in-use') {
        dispatch({ type: 'SET_ERROR', payload: '이미 사용 중인 이메일입니다.' });
      } else if (error.code === 'auth/credential-already-in-use') {
        dispatch({ type: 'SET_ERROR', payload: '이미 사용 중인 휴대전화 번호입니다.' });
      } else {
        dispatch({ type: 'SET_ERROR', payload: '회원가입 중 오류가 발생했습니다.' });
        console.error('회원가입 오류:', error);
      }
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  return (
    <>
      <Header />
      <div className="signup-pageWrapper">
        <div className="signup-formWrapper">
          <h2 className="signup-title">회원가입</h2>
          <form onSubmit={handleSignUp}>
            <NameInput
              name={state.name}
              setName={(name) => dispatch({ type: 'SET_NAME', payload: name })}
            />
            <EmailInput
              onEmailChange={(email) => dispatch({ type: 'SET_EMAIL', payload: email })}
              emailCheckMessage={state.emailCheckMessage}
              setEmailCheckMessage={(msg) =>
                dispatch({ type: 'SET_EMAIL_CHECK_MESSAGE', payload: msg })
              }
            />
            <PasswordInput
              onPasswordChange={(password) => dispatch({ type: 'SET_PASSWORD', payload: password })}
              onConfirmPasswordChange={(confirmPassword) =>
                dispatch({ type: 'SET_CONFIRM_PASSWORD', payload: confirmPassword })
              }
              passwordError={state.passwordError}
            />
            <BirthdayInput
              onBirthdayChange={(birthday) => dispatch({ type: 'SET_BIRTHDAY', payload: birthday })}
            />
            <PhoneInput
              phoneCheckMessage={state.phoneCheckMessage}
              setPhoneCheckMessage={(msg) =>
                dispatch({ type: 'SET_PHONE_CHECK_MESSAGE', payload: msg })
              }
              onPhoneChange={handlePhoneChange}
              onSendVerification={setVerificationId}
              setVerificationCode={setVerificationCode}
            />
            <div className="signup-termsWrapper">
              <label htmlFor="terms" className="signup-termsLabel">
                <span
                  className="signup-termsLink"
                  onClick={() => dispatch({ type: 'SET_MODAL_OPEN', payload: true })}
                >
                  이용약관
                </span>
                에 동의합니다.
              </label>
              <input
                type="checkbox"
                id="terms"
                checked={state.termsChecked}
                onChange={(e) =>
                  dispatch({ type: 'SET_TERMS_CHECKED', payload: e.target.checked })
                }
                className="signup-checkbox"
              />
            </div>
            {state.error && <p className="signup-error">{state.error}</p>}
            <div className="signup-buttonWrapper">
              {state.loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <button type="submit" className="signup-button signup-buttonPrimary">
                  인증번호 확인 및 회원가입하기
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      <TermsModal
        isOpen={state.modalIsOpen}
        onRequestClose={() => dispatch({ type: 'SET_MODAL_OPEN', payload: false })}
      />
    </>
  );
};

export default SignUpPage;
