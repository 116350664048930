import React, { useState, useRef, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../../../firebase';
import { PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth';

const PhoneInput = ({ onPhoneChange, setPhoneCheckMessage, onSendVerification, setVerificationCode }) => {
  const [phonePart1, setPhonePart1] = useState('');
  const [phonePart2, setPhonePart2] = useState('');
  const [phonePart3, setPhonePart3] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [smsError, setSmsError] = useState('');
  const recaptchaVerifierRef = useRef(null);

  // 휴대전화 번호가 올바른지 확인하는 함수
  const isPhoneValid = phonePart1.length === 3 && (phonePart2.length === 3 || phonePart2.length === 4) && phonePart3.length === 4;

  // 휴대전화 번호 형식을 부모 컴포넌트로 전달
  const handlePhoneChange = () => {
    const formattedPhone = `${phonePart1}-${phonePart2}-${phonePart3}`;
    onPhoneChange(formattedPhone);
  };

  // useEffect를 사용하여 처음 렌더링될 때 reCAPTCHA 설정
  useEffect(() => {
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(
          auth,
        'recaptcha-container',
        {
          size: 'invisible', // 보이지 않는 reCAPTCHA 사용
          callback: () => {
            console.log('reCAPTCHA solved.');
          },
          'expired-callback': () => {
            setSmsError('reCAPTCHA가 만료되었습니다. 페이지를 새로고침하고 다시 시도해주세요.');
          },
        },
      );
    }
  }, []);

  // 휴대전화 번호 중복 확인 함수
  const checkPhoneDuplicate = async (phone) => {
    try {
      const q = query(collection(db, 'users'), where('phone', '==', phone));
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty; // 중복이 있으면 true 반환
    } catch (error) {
      setSmsError('중복 확인 중 오류가 발생했습니다. 다시 시도해주세요.');
      return true; // 오류가 발생한 경우 중복으로 처리하여 SMS 전송을 막음
    }
  };

  // SMS 발송 함수
  const sendVerificationCode = async () => {
    const fullPhone = `+82${phonePart1}${phonePart2}${phonePart3}`;
    const formattedPhone = `${phonePart1}-${phonePart2}-${phonePart3}`;

    if (isPhoneValid) {
      try {
        // 1. 휴대전화 번호 중복 확인
        const isDuplicate = await checkPhoneDuplicate(formattedPhone);
        if (isDuplicate) {
          setSmsError('이미 등록된 전화번호입니다.');
          return;
        }

        // 2. SMS 인증 발송 및 verificationId 생성
        setPhoneCheckMessage('사용 가능한 전화번호입니다.');
        handlePhoneChange();
        const appVerifier = recaptchaVerifierRef.current;
        const provider = new PhoneAuthProvider(auth);
        const verificationId = await provider.verifyPhoneNumber(fullPhone, appVerifier);

        // 3. 부모 컴포넌트에 verificationId 전달
        onSendVerification(verificationId);
        setIsCodeSent(true);
        setSmsError(''); // 오류 메시지 초기화
      } catch (error) {
        console.error('SMS 전송 실패:', error);
        setSmsError('SMS 전송에 실패했습니다. 다시 시도해주세요.');
      }
    } else {
      setSmsError('휴대전화 번호를 모두 입력해주세요.');
    }
  };

  return (
    <div className="signup-inputWrapper">
      {/* 휴대전화 입력 필드 */}
      <label htmlFor="phone" className="signup-label">
        휴대전화 번호
      </label>
      <div className="signup-phoneWrapper">
        <input
          type="tel"
          id="phonePart1"
          placeholder="010"
          value={phonePart1}
          onChange={(e) => {
            setPhonePart1(e.target.value.replace(/\D/g, ''));
          }}
          required
          className="signup-input signup-phoneInput"
          maxLength={3}
        />
        <span className="separator">-</span>
        <input
          type="tel"
          id="phonePart2"
          placeholder="1234"
          value={phonePart2}
          onChange={(e) => {
            setPhonePart2(e.target.value.replace(/\D/g, ''));
          }}
          required
          className="signup-input signup-phoneInput"
          maxLength={4}
        />
        <span className="separator">-</span>
        <input
          type="tel"
          id="phonePart3"
          placeholder="5678"
          value={phonePart3}
          onChange={(e) => {
            setPhonePart3(e.target.value.replace(/\D/g, ''));
          }}
          required
          className="signup-input signup-phoneInput"
          maxLength={4}
        />
        <button
          type="button"
          className={`signup-checkButton ${!isPhoneValid ? 'disabled-button' : ''}`}
          onClick={sendVerificationCode}
          disabled={!isPhoneValid}
        >
          SMS 인증
        </button>
      </div>

      {/* 중복 확인 및 오류 메시지 표시 */}
      {smsError && <p className="phone-check-message error-message">
        {smsError}
      </p>}

      {/* reCAPTCHA 설정을 위한 div */}
      <div id="recaptcha-container"></div>

      {/* 인증 코드 입력 필드 */}
      {isCodeSent && (
        <div className="signup-codeWrapper">
          <input
            type="text"
            id="verificationCode"
            placeholder="인증코드를 입력하세요"
            onChange={(e) => setVerificationCode(e.target.value)}
            required
            className="signup-input"
          />
        </div>
      )}
    </div>
  );
};

export default PhoneInput;
