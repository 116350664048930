import React, { useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const EmailInput = ({ onEmailChange, emailCheckMessage, setEmailCheckMessage }) => {
  const [email, setEmail] = useState('');
  const [domain, setDomain] = useState('');

  const domains = ['gmail.com', 'naver.com', 'daum.net', 'outlook.com', 'kakao.com'];

  const checkDuplicate = async (field, value) => {
    const q = query(collection(db, 'users'), where(field, '==', value));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleEmailCheck = async () => {
    setEmailCheckMessage('');
    const fullEmail = domain ? `${email}@${domain}` : email;
    if (email && domain) {
      const isEmailDuplicate = await checkDuplicate('email', fullEmail);
      setEmailCheckMessage(isEmailDuplicate ? '이미 사용 중인 이메일입니다.' : '사용 가능한 이메일입니다.');
    } else {
      setEmailCheckMessage('이메일과 도메인을 모두 입력해주세요.');
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    onEmailChange(`${newEmail}@${domain}`); // fullEmail을 부모로 전달
  };

  const handleDomainChange = (event, newValue) => {
    setDomain(newValue);
    onEmailChange(`${email}@${newValue}`); // fullEmail을 부모로 전달
  };

  // 이메일과 도메인이 모두 입력되었는지 확인하여 중복체크 버튼 활성화
  const isEmailValid = email !== '' && domain !== '';

  return (
    <div className="signup-inputWrapper">
      <label htmlFor="email" className="signup-label">이메일</label>
      <div className="signup-emailInputWrapper" style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          id="email"
          label="아이디"
          placeholder="아이디를 입력하세요"
          value={email}
          onChange={handleEmailChange}
          required
          className="signup-input"
          style={{ marginRight: '10px',  width: '200px' }}
        />
        <span>@</span>
        <Autocomplete
          disablePortal
          options={domains}
          sx={{ width: 200, marginLeft: '10px' }}
          renderInput={(params) => <TextField {...params} label="도메인" />}
          onInputChange={handleDomainChange}
          freeSolo // 사용자가 도메인을 직접 입력할 수 있도록 허용
        />
        <button
          type="button"
          className={`signup-checkButton ${!isEmailValid ? 'disabled-button' : ''}`}
          onClick={handleEmailCheck}
          disabled={!isEmailValid} // 이메일과 도메인이 모두 입력되지 않으면 비활성화
        >
          중복체크
        </button>
      </div>
      {emailCheckMessage && <p className="signup-checkMessage">{emailCheckMessage}</p>}
    </div>
  );
};

export default EmailInput;
